import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="m29.465 27.441-7.656-7.656a10.722 10.722 0 0 0 2.287-6.623c0-5.954-4.826-10.78-10.78-10.78S2.535 7.208 2.535 13.161s4.826 10.78 10.78 10.78a10.72 10.72 0 0 0 6.271-2.023l7.701 7.701zm-22.77-7.659c-1.768-1.768-2.742-4.12-2.742-6.621s.974-4.852 2.742-6.621c1.768-1.768 4.12-2.742 6.621-2.742s4.852.974 6.621 2.742 2.742 4.12 2.742 6.621-.974 4.852-2.742 6.621-4.12 2.742-6.621 2.742-4.852-.974-6.621-2.742" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;