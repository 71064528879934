import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="M5.162 6.476a2.67 2.67 0 0 0-2.626 2.699v13.639a2.68 2.68 0 0 0 2.626 2.71h21.676a2.68 2.68 0 0 0 2.626-2.71V9.175a2.67 2.67 0 0 0-2.626-2.699zm0 1.313h21.676c.189.004.375.05.544.134L16.809 17.961a1.1 1.1 0 0 1-1.598 0L4.619 7.925a1.3 1.3 0 0 1 .544-.134v-.002ZM3.869 9.021l7.479 7.092-7.358 7.348a1.49 1.49 0 0 1-.144-.646V9.177c0-.053.015-.103.021-.154h.002zm24.261 0c.01.051.016.102.021.154v13.639c0 .22-.049.438-.144.637l-7.338-7.348 7.461-7.08zm-8.415 7.993 7.184 7.194H5.112l7.194-7.184 2.001 1.888a2.48 2.48 0 0 0 3.407 0z" /></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;