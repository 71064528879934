import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '81856',
  },
  flixmedia: {
    distributorId: 18441,
    enabled: true,
  },
  hasKIM: false,
  hasCarbonHint: false,
  price: {
    hasDiscountFlag: false,
  },
  recommendations: {
    dv: {
      slots: {
        1: 'searchProductToProducts',
        3: null,
        4: 'searchReplace',
      },
    },
    atb: {
      slots: {
        1: 'searchBasketToProducts',
        2: null,
      },
    },
  },
  powerSupply: {
    histrogram: {
      included: 'https://bilder.lascana.nl/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.lascana.nl/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.lascana.nl/i/empiriecom/FuAG_specifications.svg',
    },
  },
};
