import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgInformationCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="M16.001 2.535c-7.436 0-13.465 6.028-13.465 13.464S8.564 29.464 16 29.464 29.465 23.436 29.465 16c0-7.437-6.028-13.465-13.464-13.465m0 25.523C9.342 28.058 3.943 22.659 3.943 16S9.342 3.942 16.001 3.942 28.059 9.341 28.059 16 22.66 28.058 16.001 28.058m2.812-6.936a.703.703 0 0 1-.703.703h-3.516a.704.704 0 0 1-.002-1.406h1.056v-4.686h-.218a.704.704 0 0 1-.002-1.406h.922c.388 0 .703.315.703.703v5.39h1.055c.388 0 .703.315.703.703zm-3.938-9.12A1.125 1.125 0 1 1 16 13.127a1.125 1.125 0 0 1-1.125-1.125" /></SvgIcon>;
};
const Memo = memo(SvgInformationCircle32);
export default Memo;