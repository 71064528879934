import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="M26.212 25.378c.436-.11.86-.273 1.261-.487a1.17 1.17 0 0 0-.145-2.121 3.515 3.515 0 0 1-2.268-3.254v-8.527a8.971 8.971 0 0 0-2.695-6.361 9.1 9.1 0 0 0-6.404-2.591c-4.896.041-8.912 4.057-8.952 8.957v8.527a3.468 3.468 0 0 1-2.266 3.252 1.167 1.167 0 0 0-.136 2.127c.385.196.794.338 1.212.445-1.095.769-1.905 1.984-1.905 4.121a.5.5 0 0 0 1 0c0-2.422 1.128-3.346 2.672-3.934a.508.508 0 0 0 .139-.025 18.916 18.916 0 0 1 1.529-.475c1.95-.549 4.144-1.165 4.41-3.827.018-.176.027-.362.027-.557v-.571c.71.309 1.479.475 2.274.485l.016-.002.016.002a5.819 5.819 0 0 0 2.252-.485v.572c0 2.157 1.125 3.127 2.494 3.714.655.288 1.371.487 2.058.676.475.131.951.268 1.412.427 1.636.585 2.855 1.484 2.855 3.999a.5.5 0 0 0 1 0c0-2.106-.786-3.316-1.857-4.087ZM5.07 24.011a.165.165 0 0 1 .019-.302 4.47 4.47 0 0 0 2.919-4.191v-8.522c.036-4.353 3.606-7.924 7.96-7.96 2.132.019 4.166.801 5.695 2.304a7.972 7.972 0 0 1 2.396 5.652v8.526a4.522 4.522 0 0 0 2.918 4.188.168.168 0 0 1 .022.304c-.711.381-1.498.564-2.383.53-.074-.026-.152-.043-.227-.068a23.54 23.54 0 0 0-1.379-.422c-2.307-.651-3.72-1.165-3.72-3.403v-1.186c2.162-1.395 3.887-4.05 3.887-7.257a.5.5 0 0 0-.5-.5c-3.554 0-9.735-3.252-9.797-3.285a.5.5 0 0 0-.234-.058h-.001a3.863 3.863 0 0 0-3.842 3.843c0 3.186 1.703 5.826 3.845 7.228v1.214c0 2.234-1.492 2.803-3.599 3.361-.035.009-.063.031-.094.047-.5.141-1.026.293-1.542.483a5.048 5.048 0 0 1-2.343-.526m13.419-5.213a5.311 5.311 0 0 1-.448.235 5.54 5.54 0 0 1-.454.191c-.002 0-.004 0-.006.002a4.674 4.674 0 0 1-1.589.292c-2.861 0-6.188-3.195-6.188-7.314a2.861 2.861 0 0 1 2.726-2.842c.868.45 6.063 3.077 9.634 3.322-.168 2.773-1.765 5.02-3.674 6.113Z" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;