import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';
import { productConfig } from './product/product';
import { installmentCalculatorConfig } from './product/installmentCalculator';
import { customerRatingsConfig } from './product/customerRatings';

const tenantId = 'lascananl';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'lascana.nl' },
    clientId: 'LascanaNl',
    i18n: {
      defaultLocale: 'nl-NL',
      locales: ['nl-NL'],
      languages: ['nl'],
    },
    tenantId,
    company: {
      name: 'Lascana',
      seoTitleName: 'LASCANA',
    },
    headerSlots: {
      payback: false,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    search: {
      isInspiringSearchEnabled: false,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
      assetServerHost: 'https://assets.lascana.nl',
    },
    middleware: {
      cmsRewrites: {
        exact: ['/', '/storefront', '/fr'],
        prefixes: [
          '/service-hilfe',
          '/aide-service',
          '/lp',
          '/magazin',
          '/magazine',
          '/node',
          '/klantenservice',
          '/special',
        ],
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.font-normal-300': {
          fontWeight: 'fontWeightLight',
        },
      },
    },
    product: productConfig,
    recommendations: {
      provider: 'empiriecom',
    },
    installmentCalculator: installmentCalculatorConfig,
    order: {
      premium: { enabled: false },
      hasCo2Compensation: false,
      accountInformation: {
        bankTransferEnabled: false,
        bicAvailable: false,
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'nl' },
          { params: { type: ['socket'] }, locale: 'nl' },
        ],
      },
    },
    forms: {
      useOptimizely: true,
      apiAgnitasUrl: {
        values: {
          sendAdditionalSubscribe: true,
        },
      },
      contact: {
        selectOptionsSubject: undefined,
        dynamicHelpKey: 'lascananl', // only the key is used inside config in order to keep the config small
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|199', 'unsubscribe|299', 'unsubscribe|99'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|199',
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.ottoversand.at/service-hilfe/newsletter/abmelden/',
          voucherLink: 'https://www.ottoversand.at/service-hilfe/gutscheine-rabatte/',
        },
      },
      validation: {
        rules: {
          fileUpload: {
            acceptedFormats: 'image/*,.heic,.heif,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.pdf,.txt',
            regexAcceptedUploadFormats:
              /\.(jpe?g|png|gif|webp|bmp|heic|heif|docx?|xlsx?|pptx?|rtf|pdf|txt)$/i,
          },
          postalCode: {
            regex: /^\d{4}\s?[A-Za-z][A-Za-z]$/, // dutch postcodes are like: "1234 AB"
            errorMessage: {
              id: 'forms.type.postalcode.error',
              defaultMessage: 'Vul alsjeblieft je postcode in',
            },
          },
        },
      },
    },
    customerRatings: customerRatingsConfig,
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/b8763lc_t4tzjhg' },
    insiderPushNotifications: {
      enabled: true,
      apiKey: '532e1d55740d5727a5bcb2635a0b67f1',
      partnerName: 'lascananl',
      partnerId: 10005260,
    },
    tracking: {
      soastaId: 'E4TPQ-PBXWN-576EB-BH94Y-LYCSR',
      tagmanagerId: 'GTM-M5VHDJHK',
      googleSiteVerificationID: [
        '7moR17MZ9oChnojTPQSSshkARGweKTDrW1QI9GfTeZk',
        'SmfKtbV24V7UjCSkUXnXjYzsXlu28AMMvQnugEc-5Ww',
      ],
      msvalidateID: '30C653343D71C28456707D55556ED4BC',
      pinterestRichPinVerificationID: '67d9bf4ad3f9c8c5b41a2f8d2eba4152',
      schemaOrgOrganisation: {
        'de-AT': {
          addressCountry: 'AT',
          addressRegion: 'ST',
          addressLocality: 'Graz',
          alternateName: ['OTTO', 'Otto Versand', 'Ottoversand'],
          areaServed: 'AT',
          description:
            'Wir bei OTTO möchten das digitale Einkaufen zum Erlebnis machen. Unser Ziel ist es unsere KundInnen zu inspirieren und auf alle Wünsche passende Angebote zu liefern.',
          contactEmail: 'kundenservice@ottoversand.at',
          contactUrl: 'https://www.ottoversand.at/service-hilfe/kontakt',
          email: 'webmaster@ottoversand.at',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Otto GmbH',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Otto',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ottoversand',
            'https://www.instagram.com/ottoversand/',
            'https://www.pinterest.at/ottoversand/',
            'https://www.youtube.com/user/OTTOAustria',
            'https://www.online-shop.at/ottoversand',
          ],
          telephone: '+43-316-606-888',
          url: 'https://www.ottoversand.at',
          postalCode: '8020',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.ottoversand.at/s/',
          },
          streetAddress: 'Alte Poststraße 152',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: '',
      },
    },
    headerInformation: {
      nl: {
        description: `Een ruime collectie in badmode en lingerie koop je online bij LASCANA. Van bikini's en badpakken tot bh's en nachtmode. 14 dagen bedenktijd en gratis ruilen.`,
        title: 'LASCANA | Badmode, lingerie & nachtmode kopen bij LASCANA',
        canonical: 'https://www.lascana.nl',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      enabled: false,
      tenantId: '1000',
    },
    dynamicYieldTracking: {
      key: '',
    },
    geoCountryLayer: {
      enabled: false,
      flag: 'nl',
      alternatives: [
        {
          flag: 'de',
          shops: [{ link: 'https://www.lascana.de' }],
        },
        {
          flag: 'at',
          shops: [{ link: 'https://www.lascana.at' }],
        },
        {
          flag: 'ch',
          shops: [{ link: 'https://www.lascana.ch' }],
        },
        {
          flag: 'it',
          shops: [{ link: 'https://www.bonprix.it' }],
        },
      ],
    },
    priceFormat: 'Iso',
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
