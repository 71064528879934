import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="M16.001 2.535c-7.436 0-13.465 6.028-13.465 13.464S8.564 29.464 16 29.464 29.465 23.436 29.465 16 23.437 2.535 16.001 2.535m0 25.523C9.342 28.058 3.943 22.659 3.943 16S9.342 3.942 16.001 3.942 28.059 9.341 28.059 16 22.66 28.058 16.001 28.058" /><path fill="#1d1d1b" d="M19.333 13.196c-1.52 1.522-3.129 3.244-4.644 4.788l-2.032-1.671-.734-.606-1.217 1.481.727.605 2.705 2.228.67.551.612-.615c1.729-1.723 3.588-3.733 5.266-5.413l.676-.676-1.352-1.348z" /></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;