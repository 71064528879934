import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fill="#1d1d1b" d="m15.998 28.271-.333-.212c-.544-.363-13.129-8.442-13.129-16.157 0-3.57 1.513-6.293 4.175-7.504 2.753-1.241 6.263-.544 9.259 1.846 2.633-2.39 5.96-3.147 8.866-1.967 2.906 1.18 4.629 4.024 4.629 7.625 0 7.716-12.587 15.794-13.129 16.127l-.338.241ZM9.643 5.061a5.835 5.835 0 0 0-2.391.484c-2.178.996-3.449 3.298-3.449 6.354 0 6.354 10.167 13.494 12.196 14.826 1.997-1.361 12.196-8.472 12.196-14.826 0-3.056-1.422-5.476-3.843-6.444-2.572-1.058-5.6-.242-7.897 2.088l-.424.424-.454-.393a9.295 9.295 0 0 0-5.934-2.509z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;